.credit-report {

  h5,
  h4,
  h6 {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 600;
  }

  .hdr-cont {
    display: flex;
    align-items: center;
  }

  td.amt {
    text-align: right;
  }

  .trades {
    td {
      vertical-align: middle;
    }

    .trade-dates div {
      width: 150px;
      font-size: 0.8em;
      display: flex;
      justify-content: space-between;
    }

    .trade-history-grid {

      .year {
        display: flex;
        flex-flow: row;
      }

      .months {
        display: flex;
        flex-flow: row-reverse;

        div {
          text-align: center;
          padding: 0.1em;
          border: 1px solid #ccc;
          width: 1.5em;
        }

        .year-label {
          width: 3em;
        }
      }

      .months.m-hdr {
        flex-flow: row;
      }
    }
  }

  @media (max-width:479px) {
    .account-report{
      font-size: 14px;
    }
  }

  .cr-scoring {
    .score {
      font-size: 1.3em;
      display: flex;
      align-items: center;
      gap: 0.2em;
    }
  }

  .cr-reject {
    color: orange;
  }

  .denial {
    color: red;
  }

  .section-hdr {
    font-size: 1.2em;
    cursor: pointer;
  }

  .cr-summary {
    &>* {
      flex: 1;
    }

  }
}

.cr-highlights {
  width: auto;
  
  td:nth-child(2) {
    text-align: right;
  }
}

.late-pay-summary {
  width: auto;
  text-align: center;
  font-size: 0.8em;
}

.credit-report-tester {
  .cr-wrap {
    border: 1px solid #ccc;
    border-radius: 1em;
    display: flex;
    padding: 1em;

    .credit-report {
      flex: 1;
    }

    .actions {
      display: flex;
    }

    .action {
      cursor: pointer;
      font-size: 2em;
      margin-left: 0.5em;
    }
  }
}

.mortgage {
  border: solid 1px var(--bs-primary);
  color: var(--bs-primary);
  border-radius: 8px;
  padding: 5px;
}

.revolving {
  border: solid 1px var(--bs-warning);
  color: var(--bs-warning);
  border-radius: 8px;
  padding: 5px;
}

.installment {
  border: solid 1px var(--bs-success);
  color: var(--bs-success);
  border-radius: 8px;
  padding: 5px;
}

.line-of-credit {
  border: solid 1px var(--bs-info);
  color: var(--bs-info);
  border-radius: 8px;
  padding: 5px;
}

.other {
  border: solid 1px var(--bs-secondary);
  color: var(--bs-secondary);
  border-radius: 8px;
  padding: 5px;
}

@media print  {
  @page {
    margin: 0.5cm;
    size: auto;
  }

  .trades {
    visibility: visible;
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  .no-print, .credit-report-tester, 
  .oe-footer, .oe-header, .sidebar, .top-nav{
    display: none;
  }
}