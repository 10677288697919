.installer-admin-page {
  .installer-row {
    vertical-align: middle;

    .email {
      display: flex;
      justify-content: space-between;

      .installer-actions {
        display: flex;
        align-items: center;

        &>* {
          margin-left: 0.3em;
          font-size: 1.3em;
        }
      }
    }
  }
}

.installer-id {
  font-size: 0.8em;
  font-style: italic;
}