.array-editor {
  .input-wrap {
    display: flex;
  }

  .list-group-item {
    display: flex;
    align-items: center;

    span {
      flex: 1;
    }

    .action-buttons {
      opacity: 0;
      transition: opacity 0.2s;
      cursor: pointer;
    }

    &:hover .action-buttons {
      opacity: 0.8;
    }
  }
}