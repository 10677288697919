.address-inputs {
  .addr-region {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
  }

  .addr-city { flex: 2; }
  .addr-state { flex: 1; }
  .addr-zip { flex: 1; }
  .addr-country { flex: 2; }

  .addr-region>div {
    margin-bottom: 1em;
    margin-right: 0.5em;
    flex-basis: 8em;
  }
}

@media (max-width: 600px) {}