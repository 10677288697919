
.isc-editor {
  .isc-statuses {
    font-size: 0.8em;

    tr {
      cursor: pointer;

      .isc-name {
        font-size: 1.2em;
      }

      &:hover {
        background-color: #eee;
      }

      &.deprecated {
        td {
          color: var(--neutral--400);
        }
      }

      .dep-text {
        font-size: 0.9em;
        font-style: italic;
      }
    }
  }

  .isc-item-panel {
    padding: 1em;
    background-color: var(--neutral--300);
  }
}

.doc-types {
  .doc-type {
    display: flex;
    &>*:first-child {
      margin-right: 1em;
    }

    textarea {
      font-size: 0.8em;
    }

    .actions {
      padding: 0.8em;
    }
  }
}