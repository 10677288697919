.radio-toggle {
  .btn {
    margin-right: 1em;
    font-weight: 700;

    &:hover {
      transform: scale3d(1.06, 1.06, 1.01);
      border-color: var(--accent--primary-1);
      background-color: var(--accent--primary-1);
      color: var(--neutral--100);
    }
  }
}