.w-users-userformpagewrap {
  height: 100vh;
  max-width: 340px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.w-users-userloginformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformheader {
  text-align: center;
}

.w-users-userformbutton {
  width: 100%;
  text-align: center;
}

.w-users-userformfooter {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.w-users-userformerrorstate {
  margin-left: 20px;
  margin-right: 20px;
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0%;
}

.w-users-usersignupformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformsuccessstate,
.w-users-usersignupverificationmessage {
  display: none;
}

.w-users-userresetpasswordformwrapper,
.w-users-userupdatepasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-useraccountwrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-users-blockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding: 20px;
}

.w-users-useraccountformsavebutton {
  text-align: center;
  margin-right: 8px;
}

.w-users-useraccountformcancelbutton {
  text-align: center;
  color: #333;
  background-color: #d3d3d3;
}

body {
  color: #686765;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

p {
  margin-bottom: 16px;
}

/* a {
  color: #101010;
  text-decoration: none;
} */

ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

ol {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: -30px;
  padding-left: 50px;
}

img {
  max-width: 100%;
}

label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;
  display: block;
}

strong {
  color: #101010;
  font-weight: 600;
}

blockquote {
  color: #101010;
  border-left: 2px solid #d4d1ce;
  margin-bottom: 16px;
  padding: 10px 20px;
  font-family: Fraunces, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  text-align: center;
  margin-top: 5px;
}

.container {
  width: 100%;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 48px;
  padding-right: 48px;
}

.container.slider-container {
  display: flex;
}

.container.full-row-list-item-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container.hero-row-container {
  padding-left: 36px;
  padding-right: 36px;
}

.button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #101010;
  border: 2px solid #101010;
  border-radius: 40px;
  padding: 12px 22px 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: box-shadow 0.2s ease-in-out;
  display: inline-block;
}

.button:hover {
  color: #fff;
  box-shadow: 0 0 0 2px #101010;
}

.button.button-small {
  padding: 6px 16px 5px;
  font-size: 14px;
}

.button.double-button {
  margin-right: 20px;
}

.thumbnail {
  width: 100%;
  object-fit: cover;
  object-position: 50% 0%;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.section-push-out {
  margin-top: 48px;
  margin-bottom: 48px;
}

.section-push-out.no-top {
  margin-top: 0;
}

.column-grid {
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.column-grid.large {
  grid-row-gap: 48px;
}

.column-grid.style-guide {
  justify-items: start;
}

.card-padding {
  background-color: rgba(0, 0, 0, 0);
  flex: 1;
  padding: 36px;
}

.card-padding.small {
  padding: 24px;
}

.card-padding.no-bottom {
  padding-bottom: 0;
}

.card-padding.no-top {
  padding-top: 0;
}

.card-padding.inline {
  padding-top: 24px;
  padding-bottom: 24px;
}

.hero-avatar-row {
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -50px;
  margin-bottom: 24px;
  display: flex;
}

.hero-avatar-row.centered {
  flex-direction: column;
  align-items: center;
}

.section-title {
  border-bottom: 1px solid #d4d1ce;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 12px;
  display: flex;
}

.section-title.no-border {
  border-bottom-style: none;
  padding-bottom: 0;
}

.section-title-heading {
  color: #101010;
  letter-spacing: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.35;
}

.card {
  width: 100%;
  background-color: #f3eee9;
  border-radius: 14px;
  text-decoration: none;
  transition: transform 0.15s ease-in-out;
  overflow: hidden;
}

.card:hover {
  color: #101010;
  text-decoration: none;
  transform: translate(0, -3px);
}

.card.view-all-card {
  background-color: #fff;
  border: 1px solid #d4d1ce;
  padding: 24px;
  transition: background-color 0.15s ease-in-out;
}

.card.view-all-card:hover {
  background-color: #f3eee9;
  transform: none;
}

.card.social-icon-card {
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.card.no-hover {
  background-color: #f4f7fb;
  transition-property: none;
}

.card.no-hover:hover {
  transform: none;
}

.text-small {
  font-size: 14px;
  line-height: 20px;
}

.hero-image {
  width: 100%;
  height: 248px;
  object-fit: cover;
}

.avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 14px;
  margin-left: -2px;
  margin-right: 12px;
}

.avatar.avatar-centered {
  margin-bottom: 12px;
  margin-left: 0;
  margin-right: 0;
}

.h3 {
  font-size: 24px;
  line-height: 1.25;
}

.list-no-bullets {
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.list-no-bullets.border-top {
  border-top: 1px solid #d4d1ce;
}

.timeline-list {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.timeline-line {
  width: 2px;
  height: 100%;
  background-color: #d4d1ce;
  position: absolute;
  top: 10px;
  left: 7px;
}

.timeline-item {
  margin-bottom: 32px;
  padding-left: 28px;
  position: relative;
}

.timeline-dot {
  width: 16px;
  height: 16px;
  background-color: #d4d1ce;
  border: 3px solid #fff;
  border-radius: 50%;
  margin-top: 6px;
  position: absolute;
  left: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.text-large {
  font-size: 20px;
}

.font-serif {
  font-family: Fraunces, sans-serif;
  font-weight: 400;
}

.header {
  border-bottom: 1px solid #d4d1ce;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}

.icon-button {
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  background-color: #101010;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.icon-button-image {
  width: 18px;
}

.card-icon-wrap {
  background-color: #101010;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.flex-space-between {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.badge-text {
  color: #686765;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
}

.badge-text.margin-bottom-xs {
  color: #101010;
}

.mega-link {
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  font-family: Fraunces, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  transition: border-color 0.2s ease-in-out;
}

.mega-link:hover {
  border-bottom-color: #d4d1ce;
}

.mega-link-button {
  color: #fff;
  cursor: pointer;
  background-color: #101010;
  border-radius: 14px;
  margin-top: 3px;
  padding: 8px 18px;
  font-family: Fraunces, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  display: inline-block;
}

.mega-link-button:hover {
  box-shadow: 0 0 0 3px #101010;
}

.horizontal-slider {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.horizontal-slider-track {
  align-items: flex-start;
  padding: 2px 48px;
  display: flex;
  overflow: scroll;
}

.horizontal-slider-track.slider-secondary {
  padding-left: 24px;
  padding-right: 2px;
}

.slider-item-large {
  width: 90%;
  max-width: 560px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  padding-right: 48px;
  display: flex;
}

.slider-item-large.slider-padding-small {
  color: #686765;
  padding-right: 24px;
}

.page-wrap {
  max-width: 600px;
  min-height: 100vh;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.button-secondary {
  color: #101010;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #101010;
  border-radius: 40px;
  padding: 10px 20px 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: inline-block;
}

.button-secondary:hover {
  color: #fff;
  background-color: #101010;
}

.button-secondary.button-small {
  padding: 6px 16px 5px;
  font-size: 14px;
}

.list-grid {
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: grid;
}

.list-grid.tight {
  grid-row-gap: 12px;
  grid-template-rows: auto;
}

/* .badge {
  color: #101010;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #f3eee9;
  border-radius: 8px;
  padding: 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.25;
  transition: background-color .2s ease-in-out;
  display: inline-block;
} */

.full-row-list-item {
  width: 100%;
  border-bottom: 1px solid #d4d1ce;
  justify-content: space-between;
  align-items: center;
  padding: 18px 48px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: flex;
}

.full-row-list-item:hover {
  color: #101010;
  background-color: #f3eee9;
}

.margin-left-20 {
  margin-left: 20px;
}

.flex-row {
  width: 100%;
  align-items: center;
  display: flex;
}

.margin-right-20 {
  margin-right: 20px;
}

.link-hover-border-left {
  transition: padding 0.2s ease-in-out, border 0.2s ease-in-out;
}

.link-hover-border-left:hover {
  border-left: 2px solid #d4d1ce;
  padding-left: 12px;
}

.link-hover-slide-up {
  transition: border-width 0.2s ease-in-out, box-shadow 0.275s ease-in-out,
    color 0.2s ease-in-out;
}

.link-hover-slide-up:hover {
  color: #101010;
  border-bottom: 2px solid #d4d1ce;
  box-shadow: inset 0 -30px #f3eee9;
}

.link-hover-button {
  border-radius: 14px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: inline-block;
}

.link-hover-button:hover {
  color: #101010;
  background-color: #f3eee9;
}

.link-hover-border-bottom {
  color: #686765;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  font-size: 12px;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: block;
}

.link-hover-border-bottom:hover {
  color: #101010;
  border-bottom: 2px solid #d4d1ce;
}

.text-xs {
  font-size: 12px;
  line-height: 20px;
}

.icon {
  width: 22px;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s;
  display: flex;
}

.icon:hover {
  transform: scale(1.1);
}

.social-row {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.social-row.space-between {
  justify-content: space-between;
}

.social-row.centered {
  justify-content: center;
}

.template-nav-wrap {
  z-index: 10;
  background-color: #fff;
  flex-direction: row;
  align-items: stretch;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
}

.form-input {
  height: 56px;
  background-color: #f3eee9;
  border: 1px #d4d1ce;
  border-radius: 14px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.form-input:active,
.form-input:focus {
  background-color: #f3eee9;
}

.form-input::-ms-input-placeholder {
  color: #686765;
}

.form-input::placeholder {
  color: #686765;
}

.form-group {
  width: 100%;
}

.section {
  padding-bottom: 48px;
}

.section.push-top {
  padding-top: 48px;
}

.section.reversed {
  padding-top: 48px;
  padding-bottom: 0;
}

.inline-newsletter-wrap {
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.25fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
  position: relative;
}

.form {
  margin-bottom: 0;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  background-color: #f3eee9;
  justify-content: center;
  align-items: center;
  display: flex;
}

.utility-page-content {
  width: 100%;
  max-width: 300px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.form-success-message {
  color: #101010;
  background-color: #fff;
  border-radius: 10px;
}

.form-error-message {
  color: #fff;
  text-align: center;
  background-color: #101010;
  border-radius: 10px;
  font-size: 14px;
}

.margin-bottom-xs {
  margin-bottom: 4px;
}

.margin-bottom-small {
  margin-bottom: 16px;
}

.margin-bottom-medium {
  margin-bottom: 24px;
}

.margin-bottom-large {
  margin-bottom: 32px;
}

.margin-bottom-xl {
  margin-bottom: 60px;
}

.h4 {
  font-size: 22px;
  line-height: 1.25;
}

.font-sans-serif {
  font-family: Poppins, sans-serif;
  font-weight: 500;
}

.form-inline-button {
  text-align: center;
  cursor: pointer;
  background-color: #101010;
  border-radius: 9px;
  margin-right: 8px;
  padding: 7px 16px;
  transition: box-shadow 0.2s ease-in-out;
  position: absolute;
  right: 0;
}

.form-inline-button:hover {
  box-shadow: 0 0 0 2px #000;
}

.card-secondary {
  width: 100%;
  background-color: #fff;
  border-radius: 14px;
  flex: 1;
  text-decoration: none;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 3px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.card-secondary.social-icon-card {
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.card-secondary.view-all-card {
  text-align: center;
  padding: 24px;
  transition-property: background-color;
}

.card-secondary.view-all-card:hover {
  background-color: #f3eee9;
  transform: none;
}

.card-secondary.no-hover:hover {
  transform: none;
}

.form-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-items: start;
  display: grid;
}

.form-textarea {
  min-height: 120px;
  background-color: #f3eee9;
  border: 1px solid #d4d1ce;
  border-radius: 14px;
  margin-bottom: 0;
  padding: 20px;
}

.form-textarea:active,
.form-textarea:focus {
  background-color: #fff;
}

.form-textarea::-ms-input-placeholder {
  color: #686765;
}

.form-textarea::placeholder {
  color: #686765;
}

.card-padding-small {
  flex: 1;
  padding: 20px 24px;
}

.card-thumbnail {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.card-thumbnail.thumbnail-small {
  max-height: 200px;
}

.icon-wrap {
  margin-left: 12px;
  margin-right: 12px;
}

.bg-white {
  background-color: #fff;
}

.page-wrap-padding-grid {
  grid-column-gap: 0px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
}

.page-wrap-padding-grid.horizontal {
  padding-top: 0;
  padding-bottom: 0;
}

.page-wrap-padding-grid.no-top {
  padding-top: 0;
}

.invert {
  filter: invert();
}

.icon-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  display: grid;
}

.template-nav-logo {
  border-right: 1px solid #d4d1ce;
  align-items: center;
  padding: 12px 24px;
  display: flex;
}

.template-nav-logo:hover {
  background-color: #f3eee9;
}

.template-nav-item {
  text-align: center;
  border-right: 1px solid #d4d1ce;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: flex;
}

.template-nav-item:hover {
  color: #101010;
  background-color: #f3eee9;
}

.template-nav-item.page:hover {
  background-color: #fff;
}

.template-nav-page-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  display: flex;
}

.template-nav-page-button:hover {
  background-color: #f3eee9;
}

.template-nav-page-title {
  margin-bottom: 0;
  margin-left: 18px;
  margin-right: 18px;
  font-size: 22px;
}

.template-nav-menu {
  border-left: 1px solid #d4d1ce;
  align-items: stretch;
  margin-left: auto;
  display: flex;
}

.template-nav-page-icon {
  width: 18px;
}

.template-nav-page-icon.previous {
  transform: rotate(180deg);
}

.view-all-card {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider-item-small {
  width: 90%;
  max-width: 380px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  padding-right: 48px;
  display: flex;
}

.slider-item-small:hover {
  color: #101010;
}

.slider-item-small.slider-padding-small {
  color: #686765;
  padding-right: 24px;
}

.form-row {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-input-secondary {
  height: 56px;
  color: #101010;
  border: 1px #d4d1ce;
  border-radius: 14px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 3px 5px -2px rgba(0, 0, 0, 0.15);
}

.form-input-secondary:focus {
  border-color: #101010;
}

.form-input-secondary::-ms-input-placeholder {
  color: #686765;
}

.form-input-secondary::placeholder {
  color: #686765;
}

.inline-card {
  width: 100%;
  color: #101010;
  background-color: #f3eee9;
  border-radius: 14px;
  align-items: center;
  padding: 20px 28px;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  transition: transform 0.15s ease-in-out;
  display: flex;
  overflow: hidden;
}

.inline-card:hover {
  color: #101010;
  text-decoration: none;
  transform: translate(5px);
}

.icon-large {
  width: 40px;
  height: 40px;
}

.inline-card-text {
  padding-left: 20px;
  padding-right: 20px;
}

.inline-card-secondary {
  width: 100%;
  color: #686765;
  background-color: #fff;
  border-radius: 14px;
  align-items: center;
  padding: 20px 28px;
  text-decoration: none;
  transition: transform 0.15s ease-in-out;
  display: flex;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 3px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.inline-card-secondary:hover {
  transform: translate(5px);
}

.inline-card-thumbnail {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #101010;
  background-color: #f3eee9;
  border-radius: 14px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  transition: transform 0.15s ease-in-out;
  display: grid;
  overflow: hidden;
}

.inline-card-thumbnail:hover {
  text-decoration: none;
  transform: translate(5px);
}

.inline-card-thumbnail-secondary {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #686765;
  background-color: #fff;
  border-radius: 14px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr;
  grid-auto-columns: 1fr;
  text-decoration: none;
  transition: transform 0.15s ease-in-out;
  display: grid;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 3px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.inline-card-thumbnail-secondary:hover {
  transform: translate(5px);
}

.thumbnail-rounded {
  width: 100%;
  object-fit: cover;
  border-radius: 14px;
}

.thumbnail-rounded.thumbnail-small {
  max-height: 200px;
}

.link-logo {
  width: 90px;
  margin-bottom: 20px;
}

.social-icon-header-wrap {
  align-items: center;
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
}

.hero-wrap {
  margin-bottom: 24px;
}

.flex-bottom {
  align-items: flex-end;
  display: flex;
}

.flex-vertical-centered {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.bio-rich-text ul {
  margin-bottom: 16px;
  margin-left: -30px;
  padding-left: 50px;
}

.bio-rich-text li {
  margin-bottom: 8px;
}

.bio-rich-text img {
  width: 100%;
  border-radius: 14px;
  display: block;
}

.bio-rich-text figure {
  border-radius: 14px;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow: hidden;
}

.bio-rich-text figcaption {
  font-size: 14px;
  line-height: 22px;
}

.note {
  color: #101010;
  background-color: #f3eee9;
  border: 1px dashed #d4d1ce;
  border-radius: 14px;
  padding: 10px 16px;
  font-weight: 500;
}

.media-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: grid;
}

.style-guide-color-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.style-guide-color {
  width: 100%;
  height: 100px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  color: #fff;
  text-align: center;
  border-radius: 14px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 8px;
  font-size: 15px;
  display: grid;
  overflow: hidden;
}

.bg-primary-1 {
  background-color: #101010;
}

.bg-primary-2 {
  background-color: #686765;
}

.border-bottom {
  border-bottom: 1px solid #d4d1ce;
}

.link-template-menu {
  z-index: 1;
  background-color: #fff;
  border-top: 1px solid #d4d1ce;
  flex-direction: row;
  align-items: stretch;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.link-template-icon-button {
  border-right: 1px solid #d4d1ce;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.link-template-icon-button:hover {
  background-color: #f3eee9;
}

.link-template-icon-button.next {
  border-right-style: none;
}

.link-template-arrow {
  width: 16px;
}

.link-template-arrow.previous {
  transform-style: preserve-3d;
  transform: rotateX(0) rotateY(-180deg) rotateZ(0);
}

.link-template-button {
  text-align: center;
  border-right: 1px solid #d4d1ce;
  flex: 1;
  padding: 12px;
}

.link-template-button:hover {
  background-color: #f3eee9;
}

.link-template-button.last {
  border-right-style: none;
}

.utility-page-form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #fff;
  border-radius: 14px;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  justify-items: center;
  padding: 24px;
  display: grid;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 3px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.bg-borders {
  background-color: #d4d1ce;
}

.footer {
  border-top: 1px solid #d4d1ce;
  padding-top: 16px;
  padding-bottom: 16px;
}

.text-block {
  font-size: 14px;
  line-height: 24px;
}

.hero-component-section {
  background-color: #f3eee9;
  padding-top: 48px;
  padding-bottom: 48px;
}

.link-block {
  font-size: 150px;
  line-height: 0;
}

.field-label {
  color: #101010;
}

.text-block-2 {
  color: #9c600d;
}

.text-block-3 {
  width: 48%;
  color: #118a15;
  font-size: 20px;
  display: inline-block;
}

.text-block-4 {
  width: 48%;
  color: #101010;
  display: inline-block;
}

.text-block-5 {
  font-size: 12px;
}

.text-block-6 {
  color: #101010;
  margin-bottom: 9px;
  padding-bottom: 0;
}

.text-block-7 {
  font-size: 12px;
}

.text-block-8 {
  padding-top: 0;
  font-size: 12px;
}

.list-item {
  background-color: #f4f7fb;
  padding: 10px;
}

.select-field {
  height: 40px;
  background-color: #fff;
}

.text-block-9 {
  width: 100%;
  flex: 0 auto;
  display: block;
}

.text-block-10 {
  height: 40px;
  background-color: #fff;
  padding-top: 8px;
  padding-bottom: 0;
  padding-left: 19px;
}

.text-field {
  font-size: 20px;
  line-height: 30px;
}

.heading {
  margin-bottom: 21px;
  font-weight: 700;
}

.heading-2 {
  font-weight: 600;
}

.paragraph {
  padding-top: 40px;
}

.button-2 {
  text-align: left;
  background-color: #101010;
  position: static;
}

.div-block {
  color: #49b66f;
}

.text-block-11 {
  color: #675eca;
  display: block;
}

.nav-bar-top-2 {
  background-color: #fff;
  align-items: center;
  padding-bottom: 7px;
  padding-right: 12px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px #ddd;
}

.brand {
  width: 88px;
  height: 88px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.brand.w--current {
  justify-content: center;
  align-items: center;
}

.brand.nav-top-brand {
  width: 300px;
  height: 70px;
}

.image-4 {
  width: 280px;
  height: auto;
  max-width: none;
  min-width: 280px;
}

.menu-button-2 {
  width: 88px;
  height: 88px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.menu-button-2.w--open {
  color: #111;
  background-color: rgba(0, 0, 0, 0);
}

.nav-menu-top {
  text-align: right;
  flex: 1;
  padding-left: 12px;
}

.nav-link-2 {
  opacity: 0.5;
  color: #111;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #fff;
  margin-left: 0;
  margin-right: 0;
  padding: 12px 14px;
  font-family: Oswald, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  transition: opacity 0.4s;
  display: inline-block;
  position: static;
  overflow: visible;
}

.nav-link-2:hover {
  opacity: 1;
}

.nav-link-2.w--current {
  opacity: 1;
  color: #111;
}

.field-label-2 {
  color: #9b9696;
  text-align: left;
  font-weight: 400;
}

.mpayment-section {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 6%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mpayment-section.image-1 {
  color: #fff;
  background-image: linear-gradient(
      rgba(17, 17, 17, 0.6),
      rgba(17, 17, 17, 0.6)
    ),
    url("../images/solar.jpeg");
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
}

.mpayment-section.dark {
  color: #fff;
  background-image: none;
  border-top-color: #d4d1ce;
  padding-top: 5px;
  padding-bottom: 0;
}

.submit-button {
  background-color: #faaf52;
}

.form-2 {
  text-align: center;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.input {
  height: 40px;
  opacity: 1;
  outline-offset: 0px;
  text-align: center;
  text-transform: uppercase;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-radius: 0;
  outline: 1px solid #ddd;
  margin-bottom: 32px;
  padding: 11px 0 13px;
  font-family: Oswald, sans-serif;
  font-size: 15px;
  transition: border 0.6s;
}

.input:hover {
  border-bottom-color: #bbb;
}

.input:focus {
  border-bottom-color: #faaf52;
}

.heading-4 {
  color: #111;
  text-transform: uppercase;
  margin-bottom: 8px;
  padding-bottom: 0;
  font-family: Oswald, sans-serif;
  font-size: 28px;
  font-weight: 400;
}

.section-intro {
  max-width: 600px;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  margin: 77px 20px 21px;
  padding: 40px;
  display: block;
  box-shadow: 1px 1px 3px #000;
}

.grey-text {
  color: #9b9696;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}

.section-intro-no-drop-shadows {
  max-width: 600px;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 21px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px;
  display: block;
  box-shadow: 1px 1px 3px #000;
}

.section-intro-no-drop-shadows.no-margin-bottom {
  box-shadow: none;
  text-align: center;
  background-image: linear-gradient(#fff, #fff);
}

.mini-heading {
  color: #bbb;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.payment-amount-title {
  color: #101010;
  font-size: 16px;
}

.button-3 {
  height: 28px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #faaf52;
  border-radius: 0;
  padding: 0 24px;
  font-family: Oswald, sans-serif;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  transition: color 0.4s, background-color 0.4s;
  position: relative;
  overflow: hidden;
}

.button-3:hover {
  background-color: #f6a136;
}

.paymentamount {
  color: #2b940b;
  text-align: center;
  margin-bottom: 36px;
  font-size: 35px;
}

.heading-5 {
  margin-bottom: 28px;
}

.form-block {
  margin-bottom: 0;
}

.square-icon {
  width: 72px;
  height: 72px;
  border: 1px solid #e7e7e7;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
  position: relative;
}

.square-icon-line-left {
  z-index: 1;
  width: 3px;
  height: 32px;
  background-color: #faaf52;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -1px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.section-2 {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 6%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section-2.dark {
  color: #fff;
  background-image: linear-gradient(to right, #40424b, #1c1d24);
}

.service-v2-number {
  opacity: 0.1;
  background-color: rgba(0, 0, 0, 0);
  font-family: Oswald, sans-serif;
  font-size: 70px;
  font-weight: 500;
  line-height: 64px;
  position: absolute;
  top: -30px;
  left: -20px;
}

.services-v2 {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.service-v2-info {
  padding-left: 16px;
  padding-right: 8px;
  position: relative;
}

.service-v2-description {
  z-index: 1;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
}

.place-top {
  z-index: 1;
  position: relative;
}

.service-v2 {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.15);
  flex-grow: 1;
  flex-basis: 95%;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 72px 5% 48px;
  display: flex;
  position: relative;
}

.nextsteps-headers {
  z-index: 1;
  color: #fff;
  margin-top: 0;
  font-size: 35px;
  position: relative;
}

.experience {
  flex: 1;
  margin-bottom: 46px;
  padding-left: 15px;
  padding-right: 6%;
}

.story-heading {
  text-align: center;
  margin-top: 0;
  margin-bottom: 21px;
  padding-top: 42px;
}

.job {
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
  padding-top: 20px;
  padding-bottom: 0;
}

.job-time {
  color: #101010;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 20px;
}

.job-heading {
  color: #2b940b;
  display: inline-block;
}

.job-name {
  color: #bbb;
  margin-bottom: 8px;
  margin-left: 8px;
  font-size: 13px;
  line-height: 20px;
  display: inline-block;
}

.paragraph-small {
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 20px;
}

.paragraph-small.grey-text,
.bold-text {
  text-align: center;
}

.counter-number {
  font-family: Oswald, sans-serif;
  font-size: 36px;
  line-height: 40px;
}

.counters {
  width: 100%;
  background-image: linear-gradient(to right, #40424b, #1c1d24);
  display: flex;
}

.section-3 {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 6% 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section-3.no-paddings {
  padding: 0 0%;
}

.counter-divider {
  width: 32px;
  height: 3px;
  background-color: #00a3e0;
  margin-top: 24px;
  margin-bottom: 24px;
}

.counter-2 {
  color: #fff;
  text-transform: uppercase;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 88px 3% 96px;
  font-family: Oswald, sans-serif;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  box-shadow: 1px 0 rgba(255, 255, 255, 0.15);
}

.counter-2._3 {
  color: #000;
  background-image: linear-gradient(#fff, #fff);
  padding-top: 40px;
  padding-bottom: 40px;
}

.counter-2._1 {
  color: #000;
  text-align: center;
  background-image: linear-gradient(#fff, #fff);
  padding-top: 40px;
  padding-bottom: 40px;
}

.counter-2._2,
.counter-2._4 {
  color: #000;
  background-image: linear-gradient(#fff, #fff);
  padding-top: 40px;
  padding-bottom: 40px;
}

.amount {
  color: #2b940b;
  font-family: Oswald, sans-serif;
  font-size: 36px;
  line-height: 40px;
}

.service-blocks {
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 1px;
  display: flex;
  overflow: hidden;
}

.service-block {
  background-color: #fff;
  flex: 1;
  margin-top: 1px;
  padding: 88px 5% 64px;
  position: relative;
  box-shadow: 0 0 0 1px #e7e7e7;
}

.service-block-numbers {
  opacity: 0.15;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 32px;
  font-family: Oswald, sans-serif;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.service-block-number-1 {
  font-size: 32px;
  line-height: 36px;
}

.service-block-number-2 {
  margin-left: 2px;
}

.square-icon-2 {
  width: 72px;
  height: 72px;
  border: 1px solid #e7e7e7;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
  position: relative;
}

.grey-text-2 {
  color: #777;
}

.grid {
  grid-template:
    "tertet"
    "Area-2"
    "."
    / 1fr 1fr 1fr 1fr 1fr 1fr;
}

.section-4 {
  text-align: center;
  background-image: linear-gradient(#051c2c, #051c2c);
}

.image-8 {
  max-height: 190px;
  text-align: center;
}

.page-wrapper {
  height: 80vh;
}

.user-password-field {
  margin-bottom: 38px;
}

.block-content {
  margin-top: 68px;
}

.heading-6 {
  padding-top: 208px;
}

.section-5 {
  margin-top: 20px;
}

.div-block-2 {
  margin-top: 0;
}

.user-account-form {
  border: 1px solid #d4d1ce;
  border-radius: 12px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
}

.user-account-form-block {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  display: flex;
}

.image-9 {
  text-align: center;
}

/* .nav-link {
  opacity: 0.5;
  color: #111;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 12px 14px;
  font-family: Oswald, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  transition: opacity 0.4s;
  display: inline;
  position: static;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link.w--current {
  opacity: 1;
  color: #111;
} */

/* .link {
  margin-top: 12px;
  font-size: 12px;
  display: block;
} */

.sign-up-form-block {
  border: 1px solid #d4d1ce;
  border-radius: 12px;
  margin-top: 79px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.body {
  border: 1px solid #000;
  border-radius: 12px;
}

.paragraph-2 {
  color: #bb272f;
}

.bold-text-2,
.list {
  color: #101010;
}

.text-span {
  color: #b81e20;
}

.link-2 {
  color: #fff;
  background-color: #101010;
  border: 1px solid #374956;
  padding: 5px;
  display: inline-block;
}

.button-4 {
  display: block;
}

.button-5 {
  display: inline-block;
}

.html-embed {
  text-align: center;
}

@media screen and (min-width: 1440px) {
  .image-4 {
    width: 280px;
    height: auto;
    max-width: none;
    min-width: 280px;
  }

  .field-label-2 {
    color: #777;
    text-align: left;
  }

  .mpayment-section {
    mix-blend-mode: multiply;
    transition: opacity 0.2s;
  }

  .mpayment-section.image-1 {
    margin-top: 0;
  }

  .mpayment-section.dark {
    background-image: linear-gradient(#f7f7f7, #f7f7f7);
  }

  .heading-4 {
    color: #111;
  }

  .section-intro,
  .section-intro-no-drop-shadows {
    background-color: #fff;
    margin-top: 61px;
    margin-right: auto;
    padding: 40px;
  }

  .section-2 {
    mix-blend-mode: multiply;
    transition: opacity 0.2s;
  }

  .section-2.dark {
    background-image: linear-gradient(#f7f7f7, #f7f7f7);
  }

  .service-v2-number {
    color: #6165f4;
  }

  .service-v2-description {
    color: #111;
  }

  .service-v2 {
    border-color: #ddd;
  }

  .nextsteps-headers {
    color: #111;
  }

  .section-3 {
    mix-blend-mode: multiply;
    transition: opacity 0.2s;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 44px;
  }

  .container.slider-container {
    padding-left: 0;
    padding-right: 0;
  }

  .button {
    padding: 9px 19px;
    font-size: 14px;
  }

  .h3 {
    font-size: 28px;
  }

  .button-secondary,
  .badge {
    padding: 9px 19px 7px;
    font-size: 14px;
  }

  .template-nav-wrap,
  .link-logo.template-menu {
    display: none;
  }

  .link-template-menu {
    margin-left: auto;
    margin-right: auto;
  }

  .text-block-10 {
    text-align: left;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-left: 12px;
    text-decoration: none;
  }

  .nav-bar-top-2 {
    padding-right: 64px;
  }

  .brand {
    width: 64px;
    height: 64px;
    margin-top: 0;
    margin-left: 0;
    padding: 8px;
  }

  .brand.nav-top-brand {
    width: 280px;
    height: auto;
  }

  .menu-button-2 {
    width: 64px;
    height: 64px;
    padding: 20px;
    top: auto;
    bottom: auto;
    right: 0;
  }

  .menu-button-2.w--open {
    color: #111;
    background-color: rgba(0, 0, 0, 0);
  }

  .nav-menu-top {
    background-color: #fff;
    padding-left: 0;
  }

  .nav-link-2 {
    width: 100%;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: -1px;
    display: block;
  }

  .mpayment-section {
    padding: 80px 3%;
  }

  .section-intro,
  .section-intro-no-drop-shadows {
    padding-left: 8px;
    padding-right: 8px;
  }

  .section-2 {
    padding: 80px 3%;
  }

  .services-v2 {
    flex-direction: column;
  }

  .experience {
    padding-right: 12%;
  }

  .counters {
    flex-wrap: wrap;
  }

  .section-3 {
    padding: 80px 3%;
  }

  .counter-2 {
    width: 50%;
    flex: 0 auto;
    padding-top: 96px;
    padding-bottom: 96px;
    box-shadow: 1px 0 rgba(255, 255, 255, 0.15), 0 1px rgba(255, 255, 255, 0.15);
  }

  .service-blocks {
    flex-direction: column;
  }

  .service-block {
    padding: 48px 33% 24px 6%;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: -1px;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 15px;
    line-height: 24px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  .container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .container.hero-row-container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .thumbnail {
    min-height: auto;
  }

  .section-push-out {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .card-padding {
    padding: 28px;
  }

  .hero-avatar-row {
    margin-top: -40px;
  }

  .card {
    font-size: 14px;
  }

  .hero-image {
    height: 120px;
  }

  .avatar {
    width: 80px;
    height: 80px;
  }

  .h3 {
    font-size: 22px;
  }

  .icon-button {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
  }

  .icon-button-image {
    width: 16px;
  }

  .card-icon-wrap {
    min-height: auto;
    padding: 16px;
  }

  .mega-link-button {
    font-size: 36px;
  }

  .horizontal-slider-track {
    padding-left: 40px;
    padding-right: 40px;
  }

  .slider-item-large {
    width: 95%;
    padding-right: 40px;
  }

  .page-wrap {
    border-left-style: none;
    border-right-style: none;
  }

  .list-grid {
    grid-row-gap: 20px;
  }

  .full-row-list-item {
    padding-left: 40px;
    padding-right: 40px;
  }

  .section {
    padding-bottom: 40px;
  }

  .section.push-top,
  .section.reversed {
    padding-top: 40px;
  }

  .margin-bottom-medium {
    margin-bottom: 20px;
  }

  .margin-bottom-large {
    margin-bottom: 28px;
  }

  .margin-bottom-xl {
    margin-bottom: 40px;
  }

  .h4 {
    font-size: 20px;
  }

  .slider-item-small {
    width: 95%;
    padding-right: 40px;
  }

  .inline-card,
  .inline-card-thumbnail {
    font-size: 14px;
  }

  .hero-component-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .brand {
    width: 56px;
    height: 56px;
  }

  .brand.nav-top-brand {
    width: 280px;
    height: auto;
  }

  .menu-button-2 {
    width: 56px;
    height: 56px;
    padding: 16px;
  }

  .section-intro,
  .section-intro-no-drop-shadows {
    margin-bottom: 64px;
  }

  .service-v2 {
    padding-bottom: 32px;
    padding-left: 6%;
    padding-right: 6%;
  }

  .experience {
    padding-right: 8%;
  }

  .counter-2 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .service-block {
    padding-right: 6%;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .button.double-button {
    margin-bottom: 12px;
    margin-right: 12px;
  }

  .card-padding {
    padding: 20px;
  }

  .card-padding.small {
    padding: 18px;
  }

  .hero-avatar-row.centered-on-mobile {
    flex-direction: column;
    align-items: center;
  }

  .hero-image {
    height: 100px;
  }

  .h3 {
    font-size: 20px;
  }

  .text-large {
    font-size: 18px;
  }

  .card-icon-wrap {
    padding: 12px;
  }

  .mega-link-button {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 28px;
  }

  .horizontal-slider-track {
    padding-left: 20px;
    padding-right: 20px;
  }

  .slider-item-large {
    padding-right: 32px;
  }

  .full-row-list-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .icon {
    width: 20px;
    flex-wrap: wrap;
  }

  .section {
    padding-bottom: 36px;
  }

  .section.push-top,
  .section.reversed {
    padding-top: 36px;
  }

  .inline-newsletter-wrap {
    grid-row-gap: 8px;
    flex-direction: column;
    grid-template-columns: 1fr;
    align-items: flex-start;
    justify-items: start;
  }

  .margin-bottom-large {
    margin-bottom: 24px;
  }

  .margin-bottom-xl {
    margin-bottom: 36px;
  }

  .form-inline-button {
    height: 48px;
    margin-top: 8px;
    position: relative;
  }

  .card-padding-small {
    padding: 16px 20px;
  }

  .icon-wrap {
    margin-left: 8px;
    margin-right: 8px;
  }

  .icon-grid {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: flex-start;
  }

  .slider-item-small {
    padding-right: 32px;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .form-input-secondary {
    height: 48px;
  }

  .inline-card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .icon-large {
    width: 30px;
    height: 30px;
  }

  .inline-card-text {
    padding-left: 16px;
    padding-right: 16px;
  }

  .inline-card-secondary {
    padding-left: 20px;
    padding-right: 20px;
  }

  .inline-card-thumbnail,
  .inline-card-thumbnail-secondary {
    grid-template-columns: 0.4fr 1fr;
  }

  .flex-bottom.centered-on-mobile {
    flex-direction: column;
    align-items: center;
  }

  .media-grid {
    grid-template-columns: 1fr;
  }

  .style-guide-color-grid {
    grid-template-columns: 1fr 1fr;
  }

  .link-template-button.home {
    display: none;
  }

  .hero-component-section {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .field-label-2 {
    color: #111;
  }

  .input {
    height: 35px;
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;
  }

  .heading-4 {
    letter-spacing: -0.035px;
    margin-bottom: 10px;
    line-height: 1.4;
  }

  .section-intro {
    padding-left: 15px;
    padding-right: 15px;
  }

  .grey-text {
    margin-bottom: 20px;
  }

  .section-intro-no-drop-shadows {
    padding-left: 15px;
    padding-right: 15px;
  }

  .button-3 {
    margin-top: 0;
  }

  .service-v2 {
    padding-top: 64px;
    padding-bottom: 16px;
  }

  .experience {
    width: 100%;
    flex: 0 auto;
    padding-right: 0%;
  }

  .story-heading {
    margin-top: 26px;
  }

  .counters {
    flex-direction: column;
  }

  .counter-2 {
    width: 100%;
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

#w-node-_08092fdf-af6a-199b-a579-9a0a444a08ed-444a08ed,
#w-node-_0bbc1b8a-9a49-5b50-f13e-e151149f299f-149f299f,
#w-node-_0bbc1b8a-9a49-5b50-f13e-e151149f29a0-149f299f,
#w-node-_382c5ea0-c0f8-c6a4-75e8-fbdeb918844d-b918844c,
#w-node-_6b118573-40b6-b951-c07f-c4c2deaf4147-deaf4146,
#w-node-f5a4124b-6357-0ebe-d283-7b6d6f1f7ba1-6f1f7ba0,
#w-node-e7486e35-57c0-6918-b1a3-49c55f6ebed0-5f6ebed0,
#w-node-e7486e35-57c0-6918-b1a3-49c55f6ebed1-5f6ebed0,
#w-node-_6e4cd2c6-9a56-cfe2-a749-810cddadd60b-ddadd60a,
#w-node-afa6851c-218c-d22b-7ed6-a262a17983f6-a17983d9,
#w-node-_682f49e3-3a2b-e4b9-f1cd-f73eedfe60d2-edfe60b5,
#w-node-_7fb4112b-ca6b-19eb-4e32-6a2983cbe4a1-83cbe484 {
  align-self: stretch;
}

#w-node-fc793f29-b2b4-3283-621f-4d3c2eb14412-528c4837,
#w-node-ff6483d7-2b2d-8597-40fe-297fd3ed06dc-528c4837,
#w-node-_945adda3-20c0-5137-bdd1-49754cd8209f-528c4837,
#w-node-f2f7880e-5e58-fbe1-925a-66076782e5cb-528c4837,
#w-node-_1b276c78-4836-9555-6769-16f4a0fd6fa9-528c4837,
#w-node-ec6b4acb-21f5-437f-dd8b-82c79576baf9-528c4837 {
  justify-self: start;
}
