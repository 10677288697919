@media (max-width: 600px) {
  .consumer-application-form {
    flex-direction: column;
  }
}

.consumer-application-form {
  display: flex;

  .caf-menu {
    flex: 1;

    div {
      padding: 0.3em;
      color: #aaa;

      &.active {
        color: black;

        &:hover {
          color: #666;
        }
      }

      &.current {
        background-color: #eee;
      }
    }
  }

  .caf-content {
    flex: 3;
    padding: 1em;
  }

  .caf-error {
    margin-top: 1em;
  }

  .buttons {
    text-align: center;
  }
}

.tbl-btn {
  padding: 0.3em;
  cursor: pointer;
  font-size: 1.3em;
}
