.calculator-page {
  .system-details {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .system-prices {
      flex: 2;
      
      @media (min-width: 768px) {
        padding-right: 3em;
      }

      .totals-wrapper {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .system-size {
      flex: 1;
      @media (min-width: 768px) {
        margin-top: 0;
      }
    }
    
    .mobile-totals {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .adders-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    column-gap: 10px;
    width: 100%;
  }

  .fin-tenant {
    background-color: var(--neutral--200);
    padding: 1.5em 1.2em;
    border-radius: 0.5em;
  }

  .incentive-loan-details {
    .detail-wrap {
      display: flex;
      justify-content: space-between;

      .loan-detail {
        flex: 1;
      }

      .loan-tbl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em;
      }
    }
  }

  .equipment-card {
    width: 100%;
    
    @media (min-width: 768px) {
      width: 33.33%;
    }
  }

  .show-more-btn {
    margin: 0 auto;
    display: block;
    
    @media (min-width: 768px) {
      margin: 0;
    }
  }
}