.error-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  .error-cont {
    max-width: 600px;
    margin: 3% auto 15%;
    text-align: center;
    background-color: var(--neutral--300);
    padding: 2em;
    border-radius: 1.5em;
  }

  .buttons {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 2em 3em 0;

    button {
      margin: 0 0 0.5em;
    }
  }
}