.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}
  
.modal-container {
  background: white;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
  
  .close-button {
    font-size: 1.5rem;
    cursor: pointer;
    text-align: right;
    &:hover {
      color: var(--oe-blue);
    }
  }

  .modal-body {
    margin-top: 10px;
  }
}
  