.must-click {
  display: inline-block;
  border-radius: 0.2em;
  border: 1px solid #eee;

  a {
    display: inline-block;
    margin: 0.5em 0.7em;
  }

  &.clicked {
    border: 1px solid darkseagreen;
  }

  .mc-icon {
    display: inline-block;
    font-weight: bold;
    text-align: center;
    width: 1.5em;
    height: 1.5em;
    margin-left: 0.5em;

    .unclicked {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px dashed #aaa;
      font-weight: bold;
      text-align: center;
      padding: 0.1em;
      color: #aaa;
    }
  }
}