@keyframes toggle-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.installer-page {
  position: relative;

  &.logged-in .page-content {
    margin-left: var(--sidebar-width);

    .sb-open & {
      margin-left: var(--sidebar-width-open);
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  z-index: 20;

  transition: width 0.3s;
  width: var(--sidebar-width);
  height: 100vh;
  overflow-x: hidden;

  .sb-links {
    display: flex;
    flex-direction: column;
    background-color: var(--neutral--100);
    box-shadow: 0 2px 12px 0 var(--general--shadow-02);
    border-right: 1px solid var(--neutral--300);
    height: calc(100% - var(--sidebar-width));
    transition: right 0.3s;
  }

  .sb-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-self: stretch;
    padding: 1em;

    &.active {
      a {
        color: var(--accent--primary-1);
      }
    }

    .lbl {
      display: none;
      white-space: nowrap;
    }

    .icon {
      width: 3em;
    }

    a {
      color: rgb(48, 51, 80);
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;

      &:hover {
        color: var(--accent--primary-1);
      }
    }
  }

  .sb-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    border-right: 1px solid var(--neutral--300);
    box-shadow: 0 2px 12px 0 var(--general--shadow-02);
    background-color: var(--neutral--100);
    height: var(--sidebar-width);
  }

  .sb-toggle {
    .angles {
      transition: transform 0.3s;
      transform: rotate(0deg);
    }

    .lbl {
      font-size: 0.8em;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0.9em 1.3em;
    color: black;
    cursor: pointer;

    &:hover a {
      opacity: 0.7;
    }
  }
}

.sb-open .sidebar {
  width: var(--sidebar-width-open);

  .sb-toggle .angles {
    transform: rotate(180deg);
  }

  .sb-link .lbl {
    display: inline-block;
  }
}

@media screen and (max-width: 785px) {
  .installer-page.logged-in {
    .top-nav {
      margin-left: var(--sidebar-width);
    }

    .sidebar {
      left: 0;
      height: var(--sidebar-width);

      .sb-links {
        position: absolute;
        right: 100%;
        width: var(--sidebar-width-open);
        z-index: 11;
      }
    }

    &.sb-open .sb-links {
      right: calc(100% - var(--sidebar-width-open));
    }

    &.sb-open .sidebar {
      height: 100vh;
    }

    .page-content {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 786px) {
  .installer-page.logged-in {
    .page-content {
      transition: margin-left 0.3s;
    }

    &.sb-open .page-content {
      margin-left: var(--sidebar-width-open);
    }
  }
}