.image-uploader {
  label {
    display: block;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .logo-preview {
    height: 200px;
    width: 300px;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px dashed #ccc;
    background-size: contain;
    background-color: #eee;

    .logo-prompt {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: white;
      text-align: center;
      padding-bottom: 0.5em;

      .lp-up {
        font-size: 0.8em;
        border-radius: 0.6em;
        padding: 0.2em 0.8em;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .clear-img {
      position: absolute;
      top: 0.2em;
      right: 0.2em;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      .clear-img {
        opacity: 0.8;
      }
    }
  }
}