.pb-wrap {
  .pb-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    .progress {
      flex: 1;

      .progress-bar {
        background-color: var(--green--300);
      }
    }
  }

  .pb-pct {
    font-size: 0.9em;
    color: var(--neutral--500);
    padding: 0 1em;
  }
}