.top-nav {
  height: var(--sidebar-width);
  background-color: white;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  margin-bottom: 3em;

  position: sticky;
  top: 0;
  z-index: 10;

  .identity-debug {
    font-size: 0.8em;
    font-family: 'Courier New', Courier, monospace;
  }
}