.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 0.8em;

  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-right: 1px solid #e9ecef;
  overflow-y: auto;

  .dd-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 0.5em;
    }

    & > * {
      padding: 0.8em;
      width: 100%;
    }
  }

  .dd-link {
    &:hover {
      background-color: #e9ecef;
    }
  }
}