.search-container {
  position: relative;
  .search-label{
    display: block;
  }
  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateX(25%);
  }
  .search-input {
    padding-left: 1.8em;
  }
}

.search-message {
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  margin-bottom: 1em;
}